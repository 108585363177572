<template>
  <div class="main-content">
    <h3 class="marginBottom20">订单详情</h3>
    <el-card class="marginBottom20">
      <el-descriptions title="" :column="1">
        <el-descriptions-item label="订单编号">{{ info.code || '/' }}</el-descriptions-item>
        <el-descriptions-item label="订单状态">
          {{ info.state === 1 ? "交易关闭" : info.state === 5 ? "待支付" : info.state === 10 ? "待发货" : info.state === 15 ? "待收货" : info.state === 20 ? "已完成" : "/" }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card class="marginBottom20">
      <template #header>
        <h3>订单信息</h3>
      </template>
      <el-descriptions title="" :column="1">
        <el-descriptions-item label="下单人">{{ info.nickname || '/' }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{ info.userPhone || '/' }}</el-descriptions-item>
        <el-descriptions-item label="供应商名称">{{ info.supplierName || '/' }}</el-descriptions-item>
        <el-descriptions-item label="下单时间">{{ info.createTime || '/' }}</el-descriptions-item>
        <el-descriptions-item label="支付时间">{{ info.payTime || '/' }}</el-descriptions-item>
        <el-descriptions-item label="支付流水号">{{ info.payNo || '/' }}</el-descriptions-item>
        <el-descriptions-item label="下单备注">{{ info.remark || '/' }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card class="marginBottom20">
      <template #header>
        <h3>收件信息</h3>
      </template>
      <el-descriptions title="" :column="1">
        <el-descriptions-item label="收件人">{{ info.username || '/' }}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{ info.phone || '/' }}</el-descriptions-item>
        <el-descriptions-item label="收货地址">{{ info.province + info.city + info.county + info.detail || '/' }}</el-descriptions-item>
        <el-descriptions-item label="发货时间">{{ info.deliverGoodsTime || '/' }}</el-descriptions-item>
        <el-descriptions-item label="收货时间">{{ info.receiveTime || '/' }}</el-descriptions-item>
        <el-descriptions-item label="快递公司">{{ info.expressCompany || '/' }}</el-descriptions-item>
        <el-descriptions-item label="快递单号">{{ info.expressNumber || '/' }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card class="marginBottom20">
      <template #header>
        <h3>商品信息</h3>
      </template>
      <avue-crud
          :data="tableData"
          :option="option"
          :table-loading="showLoading"
      >
      </avue-crud>
      <div class="flex align-items justify-content-center marginTop20">
        <div class="total">蜜豆抵扣 : {{ info.total }}</div>
      </div>
      <div class="flex align-items justify-content-center marginTop20">
        <div class="total">{{ info.state === 1 || info.state === 5 ? '应付金额' : '实付金额'}} : ￥{{ info.price }}</div>
      </div>
    </el-card>
    <el-card class="marginBottom20" v-if="info.state === 1">
      <template #header>
        <h3>关闭信息</h3>
      </template>
      <el-descriptions title="" :column="1">
        <el-descriptions-item label="关闭时间">{{ info.closeTime || '/' }}</el-descriptions-item>
        <el-descriptions-item label="关闭原因">{{ info.closeReason || '/' }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-card class="marginBottom20" v-if="info.state === 20 && info.expressCompany === '' && info.expressNumber === ''">
      <template #header>
        <h3>物流信息</h3>
      </template>
      <el-empty description="无需物流"></el-empty>
    </el-card>

    <div class="flex">
      <el-button size="small" @click="handleBack" style="padding: 9px 45px">返回</el-button>
      <el-button type="primary" size="small" v-if="info.state === 10" @click="handleDeliver" style="padding: 9px 45px">发货</el-button>
    </div>

    <el-dialog
        title="发货"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 @submit="submit">
        <template slot="expressNumber">
          <el-input v-model="form.expressNumber"
                    placeholder="请输入 快递单号"
                    maxlength="30"
                    :show-word-limit="true"
                    type="text"
                    @input="changeSkuCode"/>
        </template>
        <template slot="tips">
          <div style="font-size: 14px;color: #606266;"><span style="color: #ff0000">*</span>无需物流则不需要填写物流信息，确定后订单状态为已完成</div>
        </template>
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { view, deliver } from '@/api/shop/order'
export default {
  name: "info",
  data(){
    return{
      info: {},
      tableData: [],
      showLoading: false,
      option:{
        title:'',
        titleSize:'',
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align:'center',
        menu: false,
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '商品名称',
            prop: 'goodsName'
          },
          {
            label: '规格',
            prop: 'spec',
            formatter: function(row, column, cellValue){
              // console.log(row, column, cellValue)
              return cellValue.join(',')
            }
          },
          {
            label: '单商品蜜豆币',
            prop: 'singlePoints'
          },
          {
            label: '数量',
            prop: 'num'
          },
          {
            label: '蜜豆币小计',
            prop: 'points'
          },
          {
            label: '成本积分',
            prop: 'costPoints'
          }
        ]
      },

      dialogVisible: false,
      form: {
        id: this.$route.query.id,
        code: '',
        supplierName: '',
        expressCompany: '',
        expressNumber: '',
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        column: [
          {
            label: '订单编号',
            prop: 'code',
            span: 24,
            row: true,
            disabled: true
          },
          {
            label: '供应商名称',
            prop: 'supplierName',
            span: 24,
            row: true,
            disabled: true
          },
          {
            label: '物流公司',
            prop: 'expressCompany',
            span: 24,
            row: true,
            maxlength: 10,
            showWordLimit: true,
            // rules: [{
            //   required: true,
            //   message: "请输入物流公司",
            //   trigger: "blur"
            // }]
          },
          {
            label: '快递单号',
            prop: 'expressNumber',
            span: 24,
            row: true,
            maxlength: 30,
            showWordLimit: true,
            // rules: [{
            //   required: true,
            //   message: "请输入快递单号",
            //   trigger: "blur"
            // }]
          },
          {
            label: '',
            labelWidth: 0,
            prop: 'tips',
            span: 24,
          }
        ]
      }
    }
  },
  async mounted(){
    await this.getView()
  },
  methods: {
    async getView(){
      const { data } = await view({id: this.$route.query.id})
      console.log(data)
      this.info = data;
      this.form = {
        id: this.$route.query.id,
        code: data.code,
        supplierName: data.supplierName,
        expressCompany: '',
        expressNumber: '',
      }
      this.tableData = data.details;
    },
    handleBack(){
      this.$router.go(-1)
    },
    // 发货
    handleDeliver(){
      this.dialogVisible = true
    },
    // 提交
    submit(form,done){
      // console.log('提交',form)
      deliver({
        id: form.id,
        expressCompany: form.expressCompany,
        expressNumber: form.expressNumber
      }).then(async res => {
        if (res.code === 200) {
          this.$message.success('发货成功')
          this.dialogVisible = false;
          await this.getView()
        } else {
          this.$message.error(res.msg)
          done()
        }
      }).catch(()=> done())
    },
    // 关闭
    handleClose(){
      this.dialogVisible = false;
    },
    changeSkuCode(e){
      const pattern = '^[0-9a-zA-z]{0,30}$';
      const regExp = new RegExp(pattern)
      if(e.match(regExp)) {
        this.form.expressNumber = e;
      }else{
        this.form.expressNumber = '';
        e = '';
      }
    }
  }
}
</script>

<style scoped>
.total{
  font-family: 'Arial Normal', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  color: #333333;
}
</style>